import { ImageDto } from '@/lib/image.dto';
import { BlackoutDateDto } from '@/lib/shared/dto/blackout-date.dto';
import { Countries } from '@/models/Countries.enum';

import { GeolocalizationDto } from './geolocalization.dto';
import { LocationAmenityDto } from './location-amenity.dto';
import { LocationCalendlyLinksDto } from './location-calendly-links.dto';
import { LocationManagerDto } from './location-manager.dto';
import { LocationMeetingSpacesDto } from './location-meeting-spaces.dto';
import { LocationOpeningHoursDto } from './location-opening-hours.dto';
import { LocationPhases } from './location-phases.enum';
import { LocationProductsDto } from './location-products.dto';

export type LocationDto = {
  id: string;
  sfId: string;
  slug: string;
  name: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  state: string;
  country: Countries;
  geolocalization: GeolocalizationDto;
  phase: LocationPhases;
  calendlyLinks: LocationCalendlyLinksDto;
  currencyIsoCode: string | null;
  brochureLink: string;
  locationManagers: LocationManagerDto[];
  images: ImageDto[];
  matterportImageLink: string | null;
  summary: string | null;
  products: LocationProductsDto;
  parkingInfos: string | null;
  parkingAlt: string | null;
  parkingGuest: string | null;
  bikeRacks: string | null;
  buildingCheckInInstructions: string | null;
  transitInfos: TransitInfo[];
  lodgingAndDining: string[];
  attractions: string[];
  embedSocialId: string | null;
  meetingSpaces: LocationMeetingSpacesDto;
  salesHighlightBanner: string | null;
  firstDayOfBusinessISO: string | null;
  email: string;
  phone: string | null;
  timezone: string;
  brandName: string | null;
  amenities: {
    standard: LocationAmenityDto[];
    unique: LocationAmenityDto[];
  };
  metroSfId: string;
  metroName: string;
  metaDescription?: string;
  isEarlyInPersonTourEnabled: boolean;
  hideOpeningDate: boolean;
  salesOfficeAddress: string | null;
  isWaitlisting: boolean;
  openingHours: LocationOpeningHoursDto;
  blackoutDates: BlackoutDateDto[];
  contractType: string | undefined;
  landlord: string | undefined;
  licensor: string | undefined | null;
  isCrawlable: boolean;
};

export enum TransitCategoryType {
  Airport = 'Airport',
  Bike = 'Bike',
  Bus = 'Bus',
  Driving = 'Driving',
  Parking = 'Parking',
  Subway = 'Subway',
  Train = 'Train',
  TransitHub = 'TransitHub',
  Other = 'Other',
}

export type TransitInfo = {
  description: string;
  category: TransitCategoryType;
};
