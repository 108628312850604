import { UserGeoLoc } from '@/hooks/useUserLocalization';
import { LatLng } from '@/models/LatLng';
import {
  CALIFORNIA_REGION,
  CANADA_COUNTRY_CODE,
  DEFAULT_COUNTRY,
  EUROPE_COUNTRIES_CODES,
  US_COUNTRY_CODE,
} from '@/utils/constants';
import { tryFetchToJson } from '@/utils/http-request';

const DEFAULT_LAT_NY = 40.6976637;
const DEFAULT_LNG_NY = -74.1197637;

export function getDefaultLatLng(): LatLng {
  return { lng: DEFAULT_LNG_NY, lat: DEFAULT_LAT_NY };
}

export const DEFAULT_USER_GEOLOCALIZATION: UserGeoLoc = {
  city: '',
  region: '',
  country: DEFAULT_COUNTRY,
  lnglat: getDefaultLatLng(),
};

export async function getLocalizationFromIPAddress(
  callbackFn: (params: {
    city: string;
    region: string;
    country: string;
    lnglat: LatLng;
  }) => void
) {
  const ipinfosUser = await tryFetchToJson<{
    loc: string;
    city: string;
    region: string;
    country: string;
  }>({
    fetchParams: { input: '/api/user-geolocation' },
    onFunctionalError: () => {
      callbackFn(DEFAULT_USER_GEOLOCALIZATION);
    },
    onTechnicalError: () => {
      callbackFn(DEFAULT_USER_GEOLOCALIZATION);
    },
  });

  if (ipinfosUser == null) return;

  return callbackFn({
    city: ipinfosUser.city,
    region: ipinfosUser.region,
    country: ipinfosUser.country,
    lnglat: getLatLngFromString(ipinfosUser.loc),
  });
}

export function getLatLngFromString(coords: string): LatLng {
  const [lat, lng] = coords.split(',').map((x) => +x);

  return {
    lat,
    lng,
  };
}

export function isEuropeCountry(country: string) {
  return EUROPE_COUNTRIES_CODES.includes(country.toLowerCase());
}

export function isCanada(country: string) {
  return country.toLowerCase() === CANADA_COUNTRY_CODE.toLowerCase();
}

export function isCalifornia(params: { region: string; country: string }) {
  const { region, country } = params;
  return (
    region.toLowerCase() === CALIFORNIA_REGION.toLowerCase() &&
    country.toLowerCase() === US_COUNTRY_CODE.toLowerCase()
  );
}
