import { tiemposFont } from '@/theme/tiemposFont';

import clsx from 'clsx';

type SecondaryHeadingProps = React.ComponentPropsWithoutRef<'h2'> & {
  fontFamilyClassName?: string;
  isBigger?: boolean;
};

export default function SecondaryHeading({
  fontFamilyClassName,
  children,
  className,
  isBigger = false,
  ...divProps
}: SecondaryHeadingProps) {
  return (
    <h2
      className={clsx(
        'font-medium leading-9',
        isBigger ? 'text-[28px] md:text-5xl' : 'text-[24px] md:text-[28px]',
        fontFamilyClassName ?? tiemposFont.className,
        className
      )}
      {...divProps}
    >
      {children}
    </h2>
  );
}
