'use client';

/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SignUpResponseDto } from '@/lib/authentication/dto/signup-response.dto';
import { Claims, Scopes } from '@/lib/users/claims';
import { UserDto } from '@/lib/users/dto/user.dto';
import { getUserById } from '@/lib/users/users';

export type LoggedUserInfo = {
  isAuthenticated?: boolean;
  token?: string;
  userId?: string;
  user?: UserDto;
  email?: string;
  checkAuth: () => Promise<void>;
  isLoading: boolean;
  checkClaims: (scope: Scopes, claims: Claims[]) => boolean;
};

export type AuthDataContextType = LoggedUserInfo & {
  signedUpUser?: SignUpResponseDto;
  setSignedUpUser: Dispatch<SetStateAction<SignUpResponseDto | undefined>>;
};

const defaultLoggedUserInfo: LoggedUserInfo = {
  isAuthenticated: false,
  token: undefined,
  checkAuth: async () => {},
  isLoading: true,
  checkClaims: () => false,
};

const defaultContextValue: AuthDataContextType = {
  ...defaultLoggedUserInfo,
  setSignedUpUser: () => null,
};

const AuthDataContext =
  React.createContext<AuthDataContextType>(defaultContextValue);

const AuthProvider = ({
  children,
}: {
  locale?: string;
  children: React.ReactNode;
}) => {
  const [loggedUserInfo, setLoggedUserInfo] = useState<LoggedUserInfo>(
    defaultLoggedUserInfo
  );
  const [signedUpUser, setSignedUpUser] = useState<SignUpResponseDto>();

  const checkAuth = async () => {
    const { checkUserToken, getUserToken } = await import(
      '@/lib/authentication/helpers'
    );
    const check = await checkUserToken();

    if (!check.isValid) {
      setLoggedUserInfo({
        isAuthenticated: false,
        checkAuth,
        isLoading: false,
        checkClaims: () => false,
      });
      return;
    }

    const { token } = await getUserToken();
    const AwsAuth = (await import('aws-amplify')).Auth;
    const currentAuthenticatedUser = await AwsAuth.currentAuthenticatedUser();
    const userId = currentAuthenticatedUser.attributes['custom:user_id'];

    const user = await getUserById(userId);

    const checkClaims = (scope: Scopes, claims: Claims[]) => {
      const claimsJson = currentAuthenticatedUser.attributes[`custom:${scope}`];
      const claimsObj = claimsJson ? JSON.parse(claimsJson) : {};

      return claims.every((claim) => claimsObj[claim]);
    };

    setLoggedUserInfo({
      isAuthenticated: true,
      token,
      email: currentAuthenticatedUser.attributes.email,
      userId,
      user,
      checkAuth,
      isLoading: false,
      checkClaims,
    });
  };

  const value = useMemo(() => {
    return {
      ...loggedUserInfo,
      signedUpUser,
      setSignedUpUser,
    };
  }, [loggedUserInfo, signedUpUser]);

  useEffect(() => {
    const initAuthAsync = async () => {
      const { initAuth } = await import('@/lib/authentication/init');
      initAuth();
      await checkAuth();
    };
    initAuthAsync();
  }, []);

  return (
    <AuthDataContext.Provider value={value}>
      {children}
    </AuthDataContext.Provider>
  );
};

const useAuthData = (): AuthDataContextType => {
  return useContext(AuthDataContext);
};

export { useAuthData };
export default AuthProvider;
