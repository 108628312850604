import { tiemposFont } from '@/theme/tiemposFont';

import clsx from 'clsx';

export default function TertiaryHeading({
  children,
  className,
  ...divProps
}: React.ComponentPropsWithoutRef<'h3'>) {
  return (
    <h3
      className={clsx(
        tiemposFont.className,
        'text-2xl lg:text-[28px] font-medium leading-8 lg:leading-9 tracking-[2.5%]',
        className
      )}
      {...divProps}
    >
      {children}
    </h3>
  );
}
