export enum LocationPriceFormat {
  CheapestPerSeat = 'Cheapest Per Seat',
  CheapestOffice = 'Cheapest Office',
}

export type LocationPricesDto = {
  currencyIsoCode: string;
  priceFormat: LocationPriceFormat;
  lowestPrices: {
    dedicatedOffice: LocationPriceItemDto | undefined | null;
    suites: LocationPriceItemDto | undefined | null;
    access: LocationPriceItemDto | undefined | null;
    virtual: LocationPriceItemDto | undefined | null;
    meetingRoom: LocationMeetingRoomPriceItemDto;
    coworkingDayPass: LocationCoworkingDayPassPriceItemDto | undefined | null;
    coworking: LocationCoworkingPriceItemDto;
    office: LocationOfficePriceItemDto;
  };
};

export type LocationMeetingRoomPriceItemDto = {
  price: number;
  perHour?: number;
  perDay?: number;
};

export type LocationCoworkingDayPassPriceItemDto = {
  price: number;
};

export type LocationOfficePriceItemDto = {
  perMonth?: number;
};

export type LocationCoworkingPriceItemDto = {
  perDay?: number;
  perMonth?: number;
};

export type LocationPriceItemDto = {
  family: string;
  price: number;
  productSquareFootage: number | null | undefined;
  seatCount: number | null | undefined;
};
