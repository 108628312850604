'use client';

import { useTransparentHeader } from '@/hooks/useTransparentHeader';

import clsx from 'clsx';

type HeaderWrapperProps = {
  containerClassName?: string;
  isNavigationTransparent?: boolean;
  children: React.ReactNode;
};

export default function HeaderWrapper({
  containerClassName,
  isNavigationTransparent,
  children,
}: HeaderWrapperProps) {
  const { isTransparent, setIsHover } = useTransparentHeader(
    isNavigationTransparent
  );

  return (
    <div
      className={clsx(
        'sticky h-[48px] sm:h-[70px] inset-x-0 z-10',
        containerClassName
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onFocus={() => setIsHover(true)}
    >
      <div
        className={clsx(
          'relative w-full h-full border-b group/header ',
          isTransparent && 'bg-transparent border-b-transparent transparent',
          !isTransparent && 'bg-white border-b-grey-90'
        )}
      >
        {children}
      </div>
    </div>
  );
}
