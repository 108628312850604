import { getServerConfig } from '@/utils/config';
import { HttpMethods, tryFetchToJson } from '@/utils/http-request';

import { UserDto } from './dto/user.dto';

export async function getUserById(id: string) {
  const url = `${getServerConfig().NEXT_PUBLIC_USER_API_URL}/users/${id}`;

  return tryFetchToJson<UserDto>({
    withAuthToken: true,
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    },
  });
}

export async function updateUser(params: { id: string; phone?: string }) {
  const { id, ...patch } = params;
  const url = `${getServerConfig().NEXT_PUBLIC_USER_API_URL}/users/${id}`;

  return tryFetchToJson<void>({
    withAuthToken: true,
    fetchParams: {
      input: url,
      init: {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(patch),
        method: HttpMethods.Patch,
      },
    },
  });
}
