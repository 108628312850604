import { UK } from '@/lib/countries';
import {
  LocationDto,
  LocationManagerDto,
  LocationPhases,
  LocationPriceFormat,
  LocationPricesDto,
  LocationProductName,
  ManagerRoles,
} from '@/lib/locations/dto';
import { LocationCalendlyLinksDto } from '@/lib/locations/dto/location-calendly-links.dto';
import {
  DEFAULT_CURRENCY_ISO_CODE,
  FR_GENERIC_NUMBER,
  UK_GENERIC_APPLICATION_NUMBER,
  US_GENERIC_APPLICATION_NUMBER,
  US_GENERIC_NUMBER,
} from '@/utils/constants';

import { Countries } from './Countries.enum';
import { Products } from './Products.enum';

const PRODUCT_SUITES_IMG = '/public-s3/images/product_suites_new.webp';
const PRODUCT_VIRTUAL_IMG = '/public-s3/images/product_virtual_new.webp';
export const OFFICE_WINDOW_IMG = '/public-s3/images/window-office.webp';
export const OFFICE_INTERIOR_IMG = '/public-s3/images/interior-office.webp';

export const GOOGLE_SEARCH_API = 'https://www.google.com/maps/search/?api=1';

export enum LocationPrimaryCtaTypes {
  GetNotified = 'get-notified',
  ScheduleCall = 'schedule-call',
  GetStarted = 'get-started',
  BookTour = 'book-tour',
}

type getLocationPrimaryCtaTypeProps = {
  phase: LocationPhases;
  isEarlyInPersonTourEnabled: boolean;
  calendlyLinks: LocationCalendlyLinksDto;
};

export function getLocationPrimaryCtaType({
  phase,
  isEarlyInPersonTourEnabled,
  calendlyLinks,
}: getLocationPrimaryCtaTypeProps): LocationPrimaryCtaTypes {
  const isLaunchLocationWithoutEarlyInPersonTourAvailable =
    phase === LocationPhases.Launch &&
    (!isEarlyInPersonTourEnabled || !calendlyLinks.inPersonTour);

  if (phase === LocationPhases.Signed) {
    return LocationPrimaryCtaTypes.GetNotified;
  }
  if (
    phase === LocationPhases.PostLaunch ||
    isLaunchLocationWithoutEarlyInPersonTourAvailable
  ) {
    return LocationPrimaryCtaTypes.ScheduleCall;
  }

  return LocationPrimaryCtaTypes.BookTour;
}

export function getGoogleMapsDirectionUrlFromString(query: string) {
  return `${GOOGLE_SEARCH_API}&query=${encodeURI(query)}`;
}

export function getGoogleMapsDirectionUrl(props: {
  city: string;
  address: string;
  brandName: string | null;
  name: string;
  state: string;
  zipCode: string;
}) {
  const { brandName, name, address, city, state, zipCode } = props;

  return `${GOOGLE_SEARCH_API}&query=${encodeURI(
    `${brandName || name} ${address} ${city} ${state} ${zipCode}`
  )}`;
}

export function sortMemberExperienceTeam(
  a: LocationManagerDto,
  b: LocationManagerDto
): number {
  if (a.role === b.role) {
    return a.name.localeCompare(b.name);
  }
  if (a.role !== ManagerRoles.CommunityManager) {
    return 1;
  }
  return -1;
}

export function getFormattedAddressByCountry({
  country,
  address,
  address2,
  city,
  state,
  zipCode,
}: {
  country: Countries;
  address: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode?: string;
}) {
  const firstPart = `${address}${address2 ? ` ${address2}` : ''}`;

  switch (country) {
    case Countries.Belgium:
    case Countries.France:
      return {
        addressLine1: firstPart,
        addressLine2: `${zipCode} ${city}, ${country}`,
      };
    case Countries.Canada:
      return {
        addressLine1: firstPart,
        addressLine2: `${city}, ${state} ${zipCode}, ${country}`,
      };
    case Countries.UnitedKingdom:
      return {
        addressLine1: firstPart,
        addressLine2: `${city} ${zipCode}, ${country}`,
      };
    default:
      return {
        addressLine1: firstPart,
        addressLine2: `${city}, ${state} ${zipCode}`,
      };
  }
}

export function getGenericPhoneNumberByCountry(country: string) {
  switch (country) {
    case Countries.France:
      return FR_GENERIC_NUMBER;
    case Countries.UnitedKingdom:
      return UK_GENERIC_APPLICATION_NUMBER;
    default:
      return US_GENERIC_NUMBER;
  }
}

export function getPhoneApplicationForm({
  location,
  country,
}: {
  location:
    | {
        phone: string | null;
        phase: LocationPhases;
        country: string;
      }
    | undefined;
  country: string;
}) {
  if (!location) {
    return country === UK
      ? UK_GENERIC_APPLICATION_NUMBER
      : US_GENERIC_APPLICATION_NUMBER;
  }

  if ([LocationPhases.Signed, LocationPhases.Launch].includes(location.phase)) {
    return getGenericPhoneNumberByCountry(location.country);
  }

  if (location.phone) {
    return location.phone;
  }

  return US_GENERIC_NUMBER;
}

export function getLocationMembershipManagerPhone({
  locationManagers,
  country,
}: {
  locationManagers: LocationManagerDto[];
  country: Countries;
}): string {
  const membershipManager = locationManagers.find(
    (manager) => manager.role === ManagerRoles.MembershipManager
  );

  if (membershipManager && membershipManager.phone) {
    return membershipManager.phone;
  }

  if (country === Countries.UnitedKingdom) {
    return UK_GENERIC_APPLICATION_NUMBER;
  }

  return US_GENERIC_APPLICATION_NUMBER;
}

export function createEmptyLocationPrices(): LocationPricesDto {
  return {
    currencyIsoCode: DEFAULT_CURRENCY_ISO_CODE,
    priceFormat: LocationPriceFormat.CheapestOffice,
    lowestPrices: {
      dedicatedOffice: null,
      suites: null,
      access: null,
      virtual: null,
      meetingRoom: {
        price: 0,
      },
      coworkingDayPass: null,
      coworking: {},
      office: {},
    },
  };
}

export function getLocationFullName(props: {
  name: string;
  brandName: string | null;
}) {
  const { name, brandName } = props;

  if (brandName && !name.includes(brandName)) {
    return `${brandName} ${name}`;
  }

  return name;
}

const displayRoles = [
  ManagerRoles.OperationsAssociate,
  ManagerRoles.CommunityManager,
];

export type VisibleLocationManager = LocationManagerDto & {
  avatarUrl: string;
  role:
    | ManagerRoles.CommunityManager
    | ManagerRoles.OperationsAssociate
    | ManagerRoles.TechnicalAudioVisualManager
    | ManagerRoles.ConferenceServicesManager;
};

export function getVisibleManagers(
  managers: LocationManagerDto[]
): VisibleLocationManager[] {
  return managers
    .filter((manager) => !!manager.avatarUrl)
    .filter((manager): manager is VisibleLocationManager =>
      displayRoles.includes(manager.role)
    );
}

export function getProductPageLink(productName: LocationProductName) {
  switch (productName) {
    case 'dedicatedOffice':
      return '/solutions/offices';
    case 'meetingRoom':
      return '/solutions/conference-and-meeting-rooms';
    case 'access':
      return '/solutions/coworking-membership';
    case 'coworkingDayPass':
      return '/solutions/coworking-day-pass';
    default:
      return `/solutions/${productName}`;
  }
}

export function getProductImage(product: Products.Suite | Products.Virtual) {
  switch (product) {
    case 'virtual':
      return PRODUCT_VIRTUAL_IMG;
    default:
      return PRODUCT_SUITES_IMG;
  }
}

export function getSearchNearByLocationsUrl(location: LocationDto) {
  const {
    city,
    geolocalization: { lat, lng },
  } = location;
  return `/locations?center=${lng},${lat}&query=${encodeURI(city)}&zoom=10`;
}
