'use client';

import Cookies from 'js-cookie';
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { useUserLocalization } from '@/hooks/useUserLocalization';
import { isCalifornia, isCanada, isEuropeCountry } from '@/lib/geolocation';
import { StringBool } from '@/models/StringBool.enum';
import { COOKIE_CONSENT_KEY } from '@/utils/constants';
import {
  COOKIE_KEY_MKTO_REFERRER,
  getCookieByKey,
  removeAllCookies,
  setCookieByKey,
} from '@/utils/cookies';

import { CookieConsentBanner } from './CookieConsentBanner';

export function CookieConsent() {
  const cookieConsent = getCookieByKey(COOKIE_CONSENT_KEY);
  const { region, country } = useUserLocalization();
  const shouldAsk =
    isEuropeCountry(country) ||
    isCalifornia({ region, country }) ||
    isCanada(country);
  const [displayCookieConsent, setDisplayCookieConsent] = useState(false);
  const showCookieConsent = shouldAsk && displayCookieConsent;
  const canTrack = !shouldAsk || cookieConsent === StringBool.True;

  useEffect(() => {
    setDisplayCookieConsent(cookieConsent === undefined);
  }, [cookieConsent]);

  useEffect(() => {
    Cookies.set(COOKIE_KEY_MKTO_REFERRER, document.referrer);
  }, []);

  return (
    <>
      {canTrack && (
        <Script
          id="hs-script-loader"
          src="//js.hs-scripts.com/44474178.js"
          strategy="lazyOnload"
          async
          defer
        />
      )}
      {showCookieConsent && (
        <CookieConsentBanner
          onAccept={() => {
            setDisplayCookieConsent(false);
            setCookieByKey(COOKIE_CONSENT_KEY, StringBool.True);
          }}
          onDeny={() => {
            setDisplayCookieConsent(false);
            removeAllCookies();
            setCookieByKey(COOKIE_CONSENT_KEY, StringBool.False);
          }}
        />
      )}
    </>
  );
}
